import React, { ReactElement, RefObject } from 'react';
import { theme } from '../../../styles/theme';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import LoadingIndicator from 'components/atomic/LoadingIndicator';

export type ButtonProps = {
  variant:
    | 'light'
    | 'dark'
    | 'black'
    | 'link'
    | 'custom'
    | 'desktopDark'
    | 'desktopLight';
  type?: 'button' | 'submit';
  title?: string;
  content?: any;
  icon?: ReactElement;
  background?: string;
  disabled?: boolean;
  color?: string;
  styles?: string;
  loading?: boolean;
  textStyles?: string;
  transition?: boolean;
  image?: ReactElement | null;
  forwardedRef?: RefObject<HTMLButtonElement> | null;
  className?: string;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  title = '',
  content,
  icon,
  variant,
  disabled,
  transition,
  color,
  image,
  loading,
  onClick,
  styles = '',
  background,
  textStyles,
  forwardedRef,
  className,
}) => {
  const { buttonStyle } = useThemeContext();

  const getCornerStyle = () => {
    switch (buttonStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-full';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-sm1';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-full';
    }
  };

  const variants = {
    light:
      'text-primary bg-secondary shadow-button border border-solid border-primary/40',
    dark: 'text-secondary bg-primary',
    black:
      'text-black bg-white border border-solid border-black/40 shadow-blackButton',
    link: 'text-black underline',
    desktopLight:
      '!h-[68px] !pl-8 !justify-start !text-left !font-bold text-primary !rounded-r-none bg-white',
    desktopDark:
      '!h-[68px] !pl-8 !justify-start !text-left !font-medium text-secondary !rounded-r-none bg-primary',
    custom: '',
  };

  let variantClasses: string = variants[variant];

  return loading ? (
    <div className={`h-button ${styles}`}>
      <LoadingIndicator size='50px' />
    </div>
  ) : (
    <button
      type={type}
      onClick={onClick}
      ref={forwardedRef}
      disabled={disabled}
      style={
        (variant === 'custom' && {
          background: background ? background : theme.primary,
          color: color ? color : '#FFFFFF',
        }) ||
        {}
      }
      className={`relative w-full flex flex-row items-center justify-center flex-shrink-0 px-3 rounded-full text-xs sm:text-base font-semibold duration-200 
        ${image ? 'h-productButton' : 'h-button'} 
        ${disabled && 'bg-black/30 opacity-30'} 
        ${transition && 'duration-300'} 
        ${variantClasses} ${styles} 
        ${getCornerStyle()} 
        ${className || ''}
      `}
    >
      {content ? content : null}
      <span className={`leading-4 sm:leading-5 ${textStyles}`}>{title}</span>
      {icon ? icon : null}
      {image ? image : null}
    </button>
  );
};

export default Button;
