import { QuestionType } from './FormDetailsEnum';

export interface ConsentConfig {
  showConsent: boolean;
  consentLabel: string;
  required: boolean;
  checkedByDefault: boolean;
}

export interface AddressConfig {
  line1: boolean;
  line2: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  country: boolean;
}

export interface NameConfig {
  firstName: boolean;
  lastName: boolean;
}

export interface ContentConfig {
  content: string;
}

export interface FileUploadConfig {
  cta: string;
}

export type Config =
  | (ConsentConfig &
      ContentConfig &
      NameConfig &
      AddressConfig &
      FileUploadConfig)
  | null;
export interface FormModuleModel {
  _id: string;
  name: string;
  title: string;
  enabled: boolean;
  active: boolean;
  brand: string;
  type: string;
  content: string;
  formDetails: FormDetailModel;
  autoDeploy: boolean;
}

export interface FormDetailOptions {
  text: string;
  isDefault: boolean;
}

export enum PropertyDestination {
  Registration = 'registration',
  Profile = 'profile',
  Form = 'form',
}

export interface Property {
  _id?: string;
  name: string;
  destination: PropertyDestination[];
  type: string;
  brandId?: string;
  sampleValue: string;
  global: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FormDetailModel {
  type: QuestionType;
  isRequired: boolean;
  text: string;
  options: FormDetailOptions[];
  subText: string;
  isLongQuestion?: boolean;
  allowedMultiple?: boolean;
  image?: string;
  config?: Config;
  property: Property;
}
