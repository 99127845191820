import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  ChangeEvent,
  SetStateAction,
} from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { ReactComponent as PauseCircle } from 'assets/icons/svg/pause-circle.svg';
import { ReactComponent as PlayCircle } from 'assets/icons/svg/play-circle.svg';
import { ReactComponent as SpeakerIcon } from 'assets/icons/svg/speaker.svg';
import { ReactComponent as MuteIcon } from 'assets/icons/svg/mute.svg';
import { ReactComponent as Close } from 'assets/icons/svg/close.svg';
import { extractYoutubeVideoId } from 'utils/extractYoutubeVideoId';
import { VideoModuleType } from 'types/ProductDetailsType';
import { useGlobal } from 'context/global/GlobalContext';
import { Animated } from 'react-animated-css';
import useElementSize from 'hooks/useElementSize';
import envVars from 'env-vars';

interface AndroidPlayerProps {
  source: VideoModuleType | null;
  setVideoSource: Dispatch<SetStateAction<VideoModuleType | null>>;
}

const AndroidPlayer: React.FC<AndroidPlayerProps> = ({
  source,
  setVideoSource,
}) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] =
    useState<boolean>(true);
  const [playing, setPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [muted, setMuted] = useState<boolean>(false);
  const [counter, setCounter] = useState<number | null>(null);
  const [showControls, toggleControls] = useState<boolean>(false);
  const [videoWrapperRef, { height }] = useElementSize();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { setIsVideoPlaying } = useGlobal();

  useEffect(() => {
    if (!source?.youtubeEmbed) {
      if (counter === 0) setCounter(null);
      if (!counter) toggleControls(false);
      const intervalId = setInterval(() => {
        if (counter) setCounter(counter - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [counter, source]);

  const resetCounter = () => setCounter(4);

  const handleVideoProgress = (event: ChangeEvent<HTMLInputElement>) => {
    resetCounter();
    if (videoRef.current) {
      const manualChange = Number(event.target.value);
      videoRef.current.currentTime =
        (videoRef.current.duration / 100) * manualChange;
      setProgress(manualChange);
    }
  };

  const handleOnTimeUpdate = () => {
    if (videoRef.current) {
      const progress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  let optimizedVideoLink: string = source?.path || '';
  if (!source?.youtubeEmbed && source?.path) {
    const hostUrl: string = envVars.REACT_APP_HOST_URL_IMAGE || '';
    const fileId: string | undefined = source?.path
      .split('?')
      .shift()
      ?.split('/')
      .pop()
      ?.split('.')
      .shift();
    optimizedVideoLink = `${hostUrl}/optimized-videos/${fileId}/video-vp9-720p.webm`;
  }

  return (
    <div className='z-[100] absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black'>
      <button
        className='z-[100] absolute right-4 top-4 w-8 min-w-8 h-8 min-h-8 flex items-center justify-center rounded-full cursor-pointer bg-drawerClose'
        onClick={() => {
          !source?.youtubeEmbed && videoRef.current?.pause();
          setVideoSource(null);
          !source?.youtubeEmbed && setIsVideoPlaying(false);
        }}
      >
        <Close fill='#414149' />
      </button>
      {source?.youtubeEmbed ? (
        <div className='relative w-full flex items-start pt-3 aspect-video'>
          <iframe
            width='100%'
            height='100%'
            title='YouTube Embed'
            src={`https://www.youtube.com/embed/${extractYoutubeVideoId(
              source.path
            )}${isAndroid ? '?fs=0' : ''}`}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>
      ) : (
        <div
          className='w-full h-max absolute top-[calc(50%)] left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          ref={videoWrapperRef}
          onClick={() => {
            if (!showControls) {
              toggleControls(true);
              resetCounter();
            }
          }}
        >
          <video
            autoPlay
            src={renderOptimizedMedia ? optimizedVideoLink : source?.path}
            muted={muted}
            ref={videoRef}
            playsInline={true}
            controls={isIOS ? true : false}
            preload='metadata'
            width='100%'
            height='100%'
            disableRemotePlayback={true}
            onTimeUpdate={handleOnTimeUpdate}
            onPause={() => setPlaying(false)}
            onError={() => setRenderOptimizedMedia(false)}
            onPlay={() => {
              setPlaying(true);
              setIsVideoPlaying(true);
              resetCounter();
            }}
          />
        </div>
      )}
      {videoRef?.current && !isIOS && (
        <Animated
          animationIn='fadeIn'
          animationOut='fadeOut'
          animateOnMount={false}
          isVisible={showControls}
          animationInDuration={300}
          animationOutDuration={800}
          className='w-full h-max absolute top-[calc(50%)] left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        >
          <div
            className='relative w-full flex flex-col items-center justify-end py-2 px-4 gap-1 bg-androidPlayer overflow-x-hidden'
            style={{ height: `${height}px` }}
            onClick={(event) => {
              event.stopPropagation();
              toggleControls(!showControls);
            }}
          >
            <button
              type='button'
              className='relative m-auto mt-14 bg-transparent'
              onClick={(event) => {
                event.stopPropagation();
                resetCounter();
                playing ? videoRef.current?.pause() : videoRef.current?.play();
              }}
            >
              {playing ? (
                <PauseCircle width={80} height={80} fill='#FFFFFF' />
              ) : (
                <PlayCircle width={80} height={80} fill='#FFFFFF' />
              )}
            </button>
            <div className='w-full flex items-center justify-between'>
              {videoRef?.current?.duration && (
                <p className='text-xs w-max text-left font-semibold text-white'>
                  {videoRef?.current?.currentTime
                    ? new Date(videoRef.current.currentTime * 1000)
                        .toISOString()
                        .slice(14, 19)
                    : '00:00'}
                  {' / '}
                  {new Date(videoRef?.current?.duration * 1000)
                    .toISOString()
                    .slice(14, 19)}
                </p>
              )}
              {muted ? (
                <MuteIcon
                  fill='white'
                  width={20}
                  height={20}
                  onClick={(event) => {
                    event.stopPropagation();
                    setMuted(!muted);
                    resetCounter();
                  }}
                />
              ) : (
                <SpeakerIcon
                  fill='white'
                  width={20}
                  height={20}
                  onClick={(event) => {
                    event.stopPropagation();
                    setMuted(!muted);
                    resetCounter();
                  }}
                />
              )}
            </div>
            <div className='w-full h-5 pb-8 rounded-[4px] overflow-x-hidden overflow-y-auto'>
              <input
                min='0'
                max='100'
                type='range'
                className='range-input w-full'
                onChange={handleVideoProgress}
                onInput={resetCounter}
                value={progress}
              />
            </div>
          </div>
        </Animated>
      )}
    </div>
  );
};

export default AndroidPlayer;
