import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressConfig, FormDetailModel } from 'types/FormTypes';
import { FormikProps } from 'formik';
import Input from 'components/atomic/Input';
import CustomSelectInput from 'components/atomic/CustomSelectInput';
import { statesWithLabelAndValue } from 'utils/states';
import { countryPhoneCodes } from 'types/Misc';
import FormImage from './FormImage';
import Cookies from 'js-cookie';

type OptionType = {
  value: string;
  label: string;
};

type FormAddressProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: any) => void;
};

const FormAddress: React.FC<FormAddressProps> = ({
  formData,
  formRef,
  name,
  updateFormField,
}) => {
  const [line1Value, setLine1] = useState<string>('');
  const [line2Value, setLine2] = useState<string>('');
  const [cityValue, setCity] = useState<string>('');
  const [stateValue, setState] = useState<string>('');
  const [countryValue, setCountry] = useState<string>(
    Cookies.get(`countryCode`) || 'US'
  );
  const [zipValue, setZip] = useState<string>('');

  const { line1, line2, city, state, country, zip } =
    (formData?.config as AddressConfig) || {};

  const { t } = useTranslation('translation', { keyPrefix: 'purchaseDetails' });

  useEffect(() => {
    if (formRef?.values[name]) {
      if (line1) {
        setLine1(formRef.values[name]?.line1);
      }

      if (line2) {
        setLine2(formRef.values[name]?.line2);
      }

      if (city) {
        setCity(formRef.values[name]?.city);
      }

      if (state) {
        setState(formRef.values[name]?.state);
      }

      if (country) {
        setCountry(formRef.values[name]?.country);
      } else {
        const existingValue = formRef?.values[name] || {};
        updateFormField(name, { ...existingValue, country: countryValue });
      }

      if (zip) {
        setZip(formRef.values[name]?.zip);
      }
    }

    if (!formRef?.values[name]) {
      updateFormField(name, { country: countryValue });
    }
  }, [line1, line2, city, state, country, zip]);

  const updateAddress = (value: any, key: string) => {
    switch (key) {
      case 'line1':
        setLine1(value);
        break;
      case 'line2':
        setLine2(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'state':
        setState(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'zip':
        setZip(value);
        break;
      default:
    }

    const existingValue = formRef?.values[name] || {};
    updateFormField(name, { ...existingValue, [key]: value });
  };

  const getSelectedCountry = (countryCode: string): OptionType | undefined => {
    const country = countryPhoneCodes.find(
      (item: any) => item.countryCode === countryCode
    );

    if (country) {
      return { value: country.countryCode, label: country.country };
    }

    return undefined;
  };

  return (
    <>
      <div className='w-full h-max flex flex-col justify-start gap-3'>
        {formData.image && (
          <FormImage image={formData.image} alt='form-question-image' />
        )}
        {formData.text && (
          <h5 className='text-left text-lg leading-formTitle font-semibold text-black'>
            {formData.text}
          </h5>
        )}
        {formData.subText && (
          <p className='my-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
            {formData.subText}
          </p>
        )}
        <div className='w-full flex flex-1 flex-col items-center justify-start gap-3'>
          {country && (
            <CustomSelectInput
              type='text'
              name='drop-down'
              styles='!mt-3'
              placeholder={'Choose Country'}
              selected={getSelectedCountry(countryValue)}
              options={countryPhoneCodes.map((item: any) => ({
                value: item.countryCode,
                label: item.country,
              }))}
              onChange={async (option) =>
                updateAddress(option.value, 'country')
              }
            />
          )}
          {state &&
            (countryValue === 'US' ? (
              <CustomSelectInput
                type='text'
                name='drop-down'
                styles='!mt-3'
                placeholder={'Choose state'}
                selected={{
                  value: stateValue,
                  label: stateValue,
                }}
                options={statesWithLabelAndValue}
                onChange={async (option) =>
                  updateAddress(option.value, 'state')
                }
              />
            ) : (
              <Input
                value={stateValue}
                onChange={(e) => updateAddress(e.target.value, 'state')}
                placeholder={'State'}
              />
            ))}
          {line1 && (
            <Input
              value={line1Value}
              onChange={(e) => updateAddress(e.target.value, 'line1')}
              placeholder={'Address line 1'}
            />
          )}
          {line2 && (
            <Input
              value={line2Value}
              onChange={(e) => updateAddress(e.target.value, 'line2')}
              placeholder={'Address line 2'}
            />
          )}
          {city && (
            <Input
              value={cityValue}
              onChange={(e) => updateAddress(e.target.value, 'city')}
              placeholder={'City'}
            />
          )}
          {zip && (
            <Input
              value={zipValue}
              onChange={(e) => updateAddress(e.target.value, 'zip')}
              placeholder={'Zip code'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FormAddress;
