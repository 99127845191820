import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalStorage } from 'utils/storage';
import { CartItem } from 'types/ShoppingExperience';
import { useGlobal } from 'context/global/GlobalContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { useCartContext } from 'context/CartContext/CartContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as Close } from 'assets/icons/svg/close.svg';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import Button from 'components/atomic/Button';
import Dragbar from 'components/atomic/Dragbar';
import DragZone from 'components/atomic/DragZone';
import useRedirectLink from 'hooks/useRedirectLink';

const CartDrawer: React.FC = () => {
  const [checkoutUri, setCheckoutUri] = useState<string>('');
  const [isControlled, setIsControlled] = useState<boolean>(true);
  const [deltaPosition, setDeltaPosition] = useState<number>(0);
  const {
    cartProducts,
    setCartProducts,
    cartOpen,
    closeCart,
    cartPosition,
    setCartPosition,
  } = useCartContext();
  const topHeight = 0;
  const bottomHeight = window.innerHeight;
  const redirectToExternalLink = useRedirectLink();
  const { user, personalDetails, logEvent, desktopView } = useGlobal();
  const { menuStyle } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.cartDrawer',
  });

  const getCartTotal = useCallback(
    (currencyCode?: string) => {
      const totalPrice = cartProducts
        .reduce(
          (total, cartItem) =>
            total +
            parseFloat(
              cartItem?.product?.discountedContextualPricing
                ? cartItem?.product?.discountedContextualPricing
                : cartItem?.product?.contextualPricing?.amount || '0'
            ) *
              cartItem.quantity,
          0
        )
        .toLocaleString(navigator.language, {
          style: 'currency',
          currency: currencyCode ? currencyCode : 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      return totalPrice;
    },
    [cartProducts]
  );

  useEffect(() => {
    if (cartProducts.length > 0) {
      const url: string = cartProducts[0].product.checkoutUri;
      let queryString: string = cartProducts
        .map(
          (cartItem: CartItem, index: number) =>
            `${index > 0 ? ',' : ''}${cartItem.product.id.slice(29)}:${
              cartItem.quantity
            }`
        )
        .join('');
      let outputUrl = url?.replace('<CART_ITEMS>', queryString);
      if (user?.email) outputUrl = outputUrl + `&checkout[email]=${user.email}`;
      if (personalDetails?.profile?.firstName)
        outputUrl =
          outputUrl +
          `&checkout[shipping_address][first_name]=${personalDetails?.profile?.firstName}`;
      if (personalDetails?.profile?.lastName)
        outputUrl =
          outputUrl +
          `&checkout[shipping_address][last_name]=${personalDetails?.profile?.lastName}`;
      setCheckoutUri(outputUrl);
    } else setCheckoutUri('');
  }, [cartProducts, user, personalDetails]);

  const handleStart = useCallback(() => setIsControlled(false), []);

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setCartPosition({ ...cartPosition, y: data.y });
    setDeltaPosition(data.deltaY);
  };

  const handleStop = useCallback(() => {
    setIsControlled(true);
    if (deltaPosition > 0) {
      setCartPosition({ ...cartPosition, y: bottomHeight });
      closeCart();
    } else {
      if (cartPosition.y === bottomHeight) {
        setCartPosition({ ...cartPosition, y: bottomHeight });
        closeCart();
      } else setCartPosition({ ...cartPosition, y: topHeight });
    }
  }, [
    topHeight,
    bottomHeight,
    deltaPosition,
    cartPosition,
    setCartPosition,
    closeCart,
  ]);

  const removeCartItem = (id: string) => {
    setTimeout(() => {
      let updatedCart: CartItem[] = cartProducts.filter(
        (cartItem: CartItem) => cartItem.id !== id
      );
      setCartProducts([...updatedCart]);
      getLocalStorage().setItem('cart-products', JSON.stringify(updatedCart));
    }, 1500);
  };

  const handleQuantity = (cartItem: CartItem, addition: boolean) => {
    if (cartProducts) {
      let updatedCart: CartItem[] = cartProducts.map((item: CartItem) => {
        if (item.id === cartItem?.id) {
          item['quantity'] = addition
            ? item['quantity'] + 1
            : item['quantity'] - 1;
          if (item['quantity'] === 0) removeCartItem(item['id']);
          return item;
        } else return item;
      });
      setCartProducts([...updatedCart]);
    }
  };

  const handleCheckout = () => {
    logEvent({
      eventType: 'ENGAGEMENTS',
      event: 'SHOPPING_CHECK_OUT',
      data: {
        products: cartProducts.map((cartItem: CartItem) => {
          return {
            productId: cartItem.id,
            quantity: cartItem.quantity,
            options: cartItem.product.options,
          };
        }),
      },
    });
    if (checkoutUri) redirectToExternalLink(checkoutUri, '_blank');
  };

  const getCornerStyle = () => {
    switch (menuStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-tl-[26px] rounded-tr-[26px]';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-tl-[10px] rounded-tr-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-tl-[26px] rounded-tr-[26px]';
    }
  };

  return (
    <>
      <div
        onClick={() => closeCart()}
        className={`absolute top-0 left-0 w-full h-full bg-transparent 
          ${cartOpen ? 'z-20 opacity-100' : '-z-20 opacity-0'}
        `}
      />
      <Draggable
        axis='y'
        bounds={{ top: topHeight, bottom: window.innerHeight }}
        onStart={handleStart}
        position={cartPosition}
        onDrag={handleDrag}
        onStop={handleStop}
        cancel='a, button, .not-draggable'
      >
        <div
          style={
            desktopView ? { boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)' } : {}
          }
          className={`z-[999] absolute left-0 w-full flex flex-col items-center justify-start bg-white duration-700 
            ${
              desktopView
                ? 'lg:h-[calc(100%_-_14px)] lg:left-[250px] lg:w-[calc(100%-624px)]'
                : 'w-full h-[88%] shadow-drawer'
            } 
            ${
              cartOpen
                ? `${desktopView ? 'lg:top-3.5' : 'top-[12%]'}`
                : 'top-[100%]'
            } 
            ${isControlled ? 'duration-400' : 'duration-0'} 
            ${getCornerStyle()}
          `}
        >
          <div className='relative top-0 left-0 w-full h-16 min-h-16 z-10 rounded-[26px] bg-white'>
            <div className='absolute top-2 left-0 right-0 mx-auto pointer-events-none'>
              <Dragbar />
            </div>
            <DragZone />
            <button
              className='z-[100] absolute right-8 top-4 w-8 min-w-8 h-8 min-h-8 flex items-center justify-center rounded-full cursor-pointer bg-drawerClose'
              onClick={() => {
                setCartPosition({ ...cartPosition, y: bottomHeight });
                setTimeout(() => closeCart(), 300);
              }}
            >
              <Close fill='#414149' />
            </button>
            <div className='z-10 relative w-full h-16 flex items-center rounded-[26px]'>
              <h1 className='w-[calc(100%-6rem)] mt-1 mr-2 ml-6 text-base font-semibold text-left leading-5'>
                {t('drawerTitle')}
              </h1>
            </div>
          </div>
          {cartProducts && cartProducts.length > 0 ? (
            <div className='relative w-full h-full max-h-full flex flex-col justify-between gap-6 px-5 pb-6 overflow-x-hidden'>
              <div className='w-full h-max flex flex-col gap-1.5 pb-0.5 overflow-auto overflow-x-hidden'>
                {cartProducts.map((cartItem: CartItem) => (
                  <div
                    key={cartItem.id}
                    className={`relative flex flex-row gap-7 px-3 py-2.5 rounded-xl delay-1000 duration-500 border border-solid border-[1px] border-lightBorder ${
                      cartItem.quantity === 0 ? 'opacity-0' : 'opacity-100'
                    }`}
                  >
                    <img
                      src={cartItem.product.image}
                      alt={cartItem.product.name}
                      className='rounded-lg aspect-square object-cover'
                      height={70}
                      width={70}
                    />
                    <div className='flex flex-col pt-1.5'>
                      <h5 className='text-sm font-semibold tracking-[0.3px]'>
                        {cartItem.product.name}
                      </h5>
                      {cartItem.product.options && (
                        <div className='h-[15px] flex flex-row gap-[3px]'>
                          {Object.entries(cartItem.product.options).map(
                            (option, index) =>
                              option[0] === 'Title' &&
                              option[1] === 'Default Title' ? null : (
                                <label
                                  className='block font-medium text-xxs text-muted'
                                  key={option[0]}
                                >
                                  {`${index > 0 ? ' | ' : ''}`}
                                  {option[1]}
                                </label>
                              )
                          )}
                        </div>
                      )}
                      <div className='flex flex-row items-baseline gap-1 mt-1.5'>
                        {cartItem?.product?.discountedContextualPricing &&
                          cartItem?.product?.discountedContextualPricing !==
                            '0' && (
                            <span className='text-xxs line-through text-muted'>
                              {`${parseFloat(
                                cartItem?.product?.contextualPricing?.amount ||
                                  '0'
                              ).toLocaleString(navigator.language, {
                                style: 'currency',
                                currency: cartItem?.product?.contextualPricing
                                  ?.currencyCode
                                  ? cartItem?.product?.contextualPricing
                                      ?.currencyCode
                                  : 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                            </span>
                          )}
                        <span className='text-xs font-semibold tracking-[0.3px] text-black'>
                          {`${parseFloat(
                            cartItem?.product?.discountedContextualPricing
                              ? cartItem?.product?.discountedContextualPricing
                              : cartItem?.product?.contextualPricing?.amount ||
                                  '0'
                          ).toLocaleString(navigator.language, {
                            style: 'currency',
                            currency: cartItem?.product?.contextualPricing
                              ?.currencyCode
                              ? cartItem?.product?.contextualPricing
                                  ?.currencyCode
                              : 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                        </span>
                      </div>
                    </div>
                    <div className='absolute right-5 bottom-4 w-max h-6 flex flex-row items-center justify-between px-1 py-1 rounded-full bg-disabledButton'>
                      <button
                        className='px-2 text-xxs font-semibold tracking-[0.3px] text-black disabled:text-muted'
                        onClick={() => handleQuantity(cartItem, false)}
                        disabled={cartItem.quantity <= 0}
                      >
                        -
                      </button>
                      <span className='text-xxs font-semibold tracking-[0.3px] text-black'>
                        {cartItem.quantity}
                      </span>
                      <button
                        className='px-2 text-xxs font-semibold tracking-[0.3px] text-black disabled:text-muted'
                        onClick={() => handleQuantity(cartItem, true)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {cartProducts && cartProducts.length > 0 && (
                <Button
                  variant='dark'
                  title={`${t('checkoutButton.callToAction')} (${getCartTotal(
                    cartProducts[0]?.product?.contextualPricing?.currencyCode ||
                      'USD'
                  )})`}
                  onClick={handleCheckout}
                />
              )}
            </div>
          ) : (
            <span className='w-[calc(100%_-_2.5rem)] mt-[50%] p-2.5 text-xs font-semibold text-center tracking-[0.1px] text-muted'>
              {t('emptyCartText')}
            </span>
          )}
        </div>
      </Draggable>
    </>
  );
};

export default CartDrawer;
