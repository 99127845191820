import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useAPI } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { showToast } from 'components/atomic/Toast/Toast';
import { AB899ModuleType } from 'types/ProductDetailsType';
import { FDA_REGULATIONS_LINK } from 'i18n/globalConstants';
import { TestResultsType, ToxicElementType } from 'types/TestResultsTypes';
import { ReactComponent as Checkmark } from 'assets/icons/svg/checkmark.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import useRedirectLink from 'hooks/useRedirectLink';
import Button from 'components/atomic/Button';
import Input from 'components/atomic/Input';
import dateFormat from 'dateformat';

type AB899DrawerProps = {
  brandId: string;
  drawerTitle: string;
  drawerData: AB899ModuleType | null;
};

const AB899Drawer: React.FC<AB899DrawerProps> = ({
  brandId = '',
  drawerData,
  drawerTitle,
}) => {
  const [lotNumber, setLotnumber] = useState<string>('');
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);
  const [testResults, setTestResults] = useState<TestResultsType[]>([]);
  const redirectToExternalLink = useRedirectLink();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.ab899Drawer',
  });

  const [fetchLotDetails] = useAPI({
    endpoint: `ab899/${brandId}/${lotNumber}`,
    method: 'GET',
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!lotNumber) {
      showToast({
        message: t('errors.emptyLotNumber'),
        type: 'error',
      });
    } else {
      setResultsLoading(true);
      try {
        let lotDetails = await fetchLotDetails();
        setTestResults(lotDetails);
      } catch (error: any) {
        showToast({
          message: t('errors.noRecordFound'),
          type: 'error',
        });
      }
      setResultsLoading(false);
    }
  };

  const materialTile = (toxicElement: ToxicElementType) => (
    <div
      id={toxicElement._id}
      className='relative w-max min-w-[calc(50%_-_6px)] flex flex-row items-center justify-between px-1.5 py-1 gap-3 rounded-full border border-solid border-checkmark bg-materialTile'
    >
      <div className='flex flex-row items-center justify-center gap-1 overflow-hidden'>
        <Checkmark
          width={14}
          height={14}
          fill='#00D7C8'
          className='min-w-[14px] mb-px'
        />
        <p className='font-semibold text-xxs text-[#00D7C8] whitespace-nowrap capitalize'>
          {toxicElement.value}
        </p>
      </div>
      <p className='text-xxs text-[#00D7C8] whitespace-nowrap'>
        {toxicElement.amount} ppb
      </p>
    </div>
  );

  return (
    <ModuleWrapper drawerTitle={drawerTitle}>
      <HtmlWrapper html={drawerData?.content} />
      <div className='relative w-full flex flex-col mt-2.5 pb-3 gap-2.5 rounded-xl border border-solid border-lightgray overflow-hidden'>
        <div className='relative py-1.5 w-full text-center bg-minimalGray'>
          <h3 className='font-bold uppercase text-lg text-gray tracking-[0.5px]'>
            {t('testResultsLookup')}
          </h3>
        </div>
        {!testResults || testResults?.length === 0 ? (
          <div className='relative flex flex-col px-3 gap-3'>
            <p className='px-3 font-semibold text-sm text-black tracking-[0.3px]'>
              {t('enterLotNumber')}
            </p>
            <form
              onSubmit={handleSubmit}
              className='relative flex flex-col gap-2.5'
            >
              <Input
                value={lotNumber}
                name='lot-number-input'
                autoComplete='lot-number-input'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setLotnumber(event.target.value)
                }
                placeholder={
                  lotNumber
                    ? t('lotNumberInputPlaceholder')
                    : t('lotNumberEmptyInputPlaceholder')
                }
              />
              <Button
                type='submit'
                variant='dark'
                title={t('submitLotNumberButton')}
                loading={resultsLoading}
              />
            </form>
          </div>
        ) : (
          <div className='relative w-full flex flex-col gap-3'>
            {testResults.length > 0 &&
              testResults?.map((result: TestResultsType, index: number) => (
                <div
                  key={result._id}
                  className='relative w-full flex flex-col px-2.5 gap-3'
                >
                  <div className='relative flex flex-row gap-3'>
                    <div className='relative flex-1 flex-col items-center justify-center text-center p-3 gap-0.5 rounded bg-minimalGray'>
                      <h6 className='font-medium text-sm text-muted'>
                        {t('lotNumberHeading')}
                      </h6>
                      <p className='font-semibold text-sm text-extraDarkGray'>
                        {result?.lotNo}
                      </p>
                    </div>
                    <div className='relative flex-1 flex-col items-center justify-center text-center p-3 gap-0.5 rounded bg-minimalGray'>
                      <h6 className='font-medium text-sm text-muted'>
                        {t('testDateHeading')}
                      </h6>
                      <p className='font-semibold text-sm text-extraDarkGray'>
                        {dateFormat(result.testingDate, 'mmm dd, yyyy')}
                      </p>
                    </div>
                  </div>
                  <div className='relative w-full p-3 bg-minimalGray rounded'>
                    <p className='text-xs text-black text-left font-bold'>
                      {t('productsTestedHeading')}
                    </p>
                    <ul className='list-disc list-inside text-xs text-black text-left'>
                      {result.productsTested?.map((product) => (
                        <li key={product._id}>{product.label}</li>
                      ))}
                    </ul>
                  </div>
                  <div className='relative flex flex-col px-2.5 pb-1.5 gap-3 rounded bg-minimalGray'>
                    <div className='relative flex flex-row items-center justify-center text-center py-1.5 px-2.5 gap-1 border border-solid border-input border-r-0 border-l-0 border-t-0'>
                      <Checkmark fill='#00D7C8' width={15} height={15} />
                      <p className='font-bold text-xs tracking-[0.5px] text-gray'>
                        {t('toxicElementsHeading')}
                      </p>
                    </div>
                    <div className='relative w-full flex flex-row flex-wrap gap-3'>
                      {result.toxicElements?.map((toxicElement) =>
                        materialTile(toxicElement)
                      )}
                    </div>
                    {result?.testResultsFile ? (
                      <Button
                        type='button'
                        variant='link'
                        styles='!h-[18px]'
                        title={t('viewFullReportButton')}
                        textStyles='!text-black !text-xs !font-medium !underline !tracking-[0.1px]'
                        icon={
                          <ExternalLink
                            width={12}
                            height={12}
                            fill='#000'
                            className='ml-0.5'
                          />
                        }
                        onClick={() =>
                          redirectToExternalLink(
                            result.testResultsFile,
                            '_blank'
                          )
                        }
                      />
                    ) : null}
                  </div>
                  {index < testResults.length - 1 && (
                    <div className='relative w-full h-px bg-lightgray my-1' />
                  )}
                </div>
              ))}
          </div>
        )}
        {testResults?.length > 0 && (
          <div className='relative w-full px-2.5'>
            <Button
              type='button'
              variant='dark'
              title={t('startOverButton')}
              onClick={() => {
                setLotnumber('');
                setTestResults([]);
              }}
            />
          </div>
        )}
        <p className='text-xxs px-6'>
          {t('disclaimer')}
          <a
            href={FDA_REGULATIONS_LINK}
            target='_blank'
            rel='noreferrer'
            className='text-brij underline'
          >
            {t('disclaimerLink')}
          </a>
          .
        </p>
      </div>
    </ModuleWrapper>
  );
};

export default AB899Drawer;
