import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Suspense,
  useRef,
} from 'react';
import { theme } from 'styles/theme';
import { Helmet } from 'react-helmet';
import { FormProvider } from 'context';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocalStorage } from 'utils/storage';
import { UrlParam, Position } from 'types/Misc';
import { FormDetailModel } from 'types/FormTypes';
import { isPreviewMode } from '../../utils/preview';
import { ContentGateType } from 'types/ContentGateType';
import { initialScreenToShow } from 'utils/screenToShow';
import { showToast } from 'components/atomic/Toast/Toast';
import { removeFormLocalStorageValues } from 'utils/forms';
import { getModulePathname } from 'utils/getModulePathname';
import { MAGIC_ACTION } from 'context/global/GlobalProvider';
import { useGlobal } from '../../context/global/GlobalContext';
import { PossibleExperienceType } from 'types/ExperienceTypes';
import { useAPICacheContext } from 'context/APICacheContext/APICacheContext';
import { ModuleButtonType } from 'components/toolbox/ButtonFactory/ButtonFactory';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';
import logGtmEvent, { PossibleGtmEventNames } from 'utils/logGtmEvent';
import {
  CornerStyle,
  useThemeContext,
} from 'context/ThemeContext/ThemeContext';
import ScreenType from 'types/ScreenType';
import useHeights from 'hooks/useHeights';
import getLogoURL from 'utils/getLogoImage';
import usePrevious from 'hooks/usePrevious';
import AgeGate from 'components/screens/AgeGate';
import useRedirectLink from 'hooks/useRedirectLink';
import getSuccessTitle from 'utils/getSuccessTitle';
import SmsOptIn from 'components/experiences/SmsOptIn';
import LinkDrawer from 'components/drawers/LinkDrawer';
import AutoDeploy from 'components/drawers/AutoDeploy';
import FormDrawer from 'components/drawers/FormDrawer';
import ShopDrawer from 'components/drawers/ShopDrawer';
import PageWrapper from 'components/layout/PageWrapper';
import VideoDrawer from 'components/drawers/VideoDrawer';
import AB899Drawer from 'components/drawers/AB899Drawer';
import ContentGate from 'components/screens/ContentGate';
import useRegisterProduct from 'hooks/useRegisterProduct';
import DigitalHub from 'components/experiences/DigitalHub';
import BottomDrawer from 'components/drawers/BottomDrawer';
import CustomDrawer from 'components/drawers/CustomDrawer';
import IframeDrawer from 'components/drawers/IframeDrawer';
import ReferralDrawer from 'components/drawers/ReferralDrawer';
import WarrantyDrawer from 'components/drawers/WarrantyDrawer';
import DocumentDrawer from 'components/drawers/DocumentDrawer';
import DiscountDrawer from 'components/drawers/DiscountDrawer';
import MediaBackground from 'components/atomic/MediaBackground';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import addWarrantyInformation from 'utils/addWarrantyInformation';
import CatchLinkWrapper from 'components/wrappers/CatchLinkWrapper';
import SweepstakesDrawer from 'components/drawers/SweepstakesDrawer';
import RegistrationDrawer from 'components/drawers/RegistratonDrawer';
import CloseAutoDeployDrawer from 'components/drawers/CloseAutoDeployDrawer';
import {
  ModuleInfoType,
  ApprovalStatus,
  LinkModuleType,
  VideoModuleType,
  AB899ModuleType,
  CustomModuleType,
  IframeModuleType,
  DocumentModuleType,
  ProductDetailsType,
  ReferralModuleType,
  ShoppingModuleType,
  WarrantyModuleType,
  DiscountModuleType,
  PossibleModulesType,
  SweepstakesModuleType,
} from '../../types/ProductDetailsType';

const getWarrantyModule = (details?: ProductDetailsType | null) => {
  return details?.product?.qrCodeDestination ===
    PossibleExperienceType.PRODUCT_EXPERIENCE
    ? details?.modules?.find(
        (module: ModuleInfoType) => module?.type === 'WARRANTY_MODULE'
      )
    : null;
};
interface Props {
  navToForm?: boolean;
}

const ProductDetails: React.FC<Props> = ({ navToForm }) => {
  const [registerCallMade, setRegisterCallMade] = useState<boolean>(false);
  const [isDrawerPageOpen, setIsDrawerPageOpen] = useState<boolean>(false);
  const [isFormNavigation, setIsFormNavigation] = useState(false);
  const [pageTitle, setPageTitle] = useState<string | undefined>('');
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [mainDrawerOpen, setMainDrawerOpen] = useState<boolean>(false);
  const [isNewUser, setNewUser] = useState<boolean>(false);

  const { registerProductAndFetch } = useRegisterProduct();
  const { bottomHeight } = useHeights(mainDrawerOpen);
  const { invalidateCache } = useAPICacheContext();
  const { id } = useParams<UrlParam>();
  const history = useHistory();
  const { setMeta, showSuccess, closeSuccessDrawer, openDrawer } =
    useSuccessDrawerContext();

  const [position, setPosition] = useState<Position>({
    x: 0,
    y: bottomHeight,
  });

  const redirectToExternalLink = useRedirectLink();

  const {
    user,
    productDetails: details,
    setSignInRedirect,
    setSlug,
    previewEvent,
    previewAuthenticated,
    logEvent,
    setMagicAction,
    setMagicPayload,
    productModule,
    loading,
    setProductModule,
    registeringProduct,
    setRegisteringProduct,
    token,
    setProductDetails,
    personalDetails,
    authFetched,
    formRegistration,
    magicAction,
    setOpenAutoDeployModal,
    openAutoDeployModal,
    userClosedAutoDeployDrawer,
    setUserClosedAutoDeployDrawer,
    toggleAgegateDisplay,
    toggleContentGateDisplay,
    setScreen,
    registrationScreen,
    emailConsent,
    registerProduct,
    allowClose,
    desktopView,
    slug,
    setReceiptData,
  } = useGlobal();

  const previousEvent: any = usePrevious(previewEvent);
  const previousProductId = useRef<string | undefined>(undefined);

  const {
    brandTheme,
    setBrandTheme,
    setSecondaryBrandColor,
    setButtonStyle,
    setMenuStyle,
    setInputStyle,
  } = useThemeContext();

  const warrantyModule = getWarrantyModule(details);

  const { t } = useTranslation('translation', {
    keyPrefix: 'productDetails',
  });
  const { t: registrationTranslation } = useTranslation('translation', {
    keyPrefix: 'registration',
  });

  useEffect(() => {
    if (details) {
      if (
        details?.product?.enableContentGate &&
        details.product.contentGateModule
      ) {
        toggleContentGateDisplay(true);
        toggleAgegateDisplay(false);
      } else if (details?.product?.ageGateEnabled) {
        toggleAgegateDisplay(true);
        toggleContentGateDisplay(false);
      }
    }
  }, [details, toggleAgegateDisplay, toggleContentGateDisplay]);

  useEffect(() => {
    setScreen(ScreenType.NONE);
  }, []);

  const onLinkClick = (href: string) => {
    logEvent({
      eventType: 'ENGAGEMENTS',
      event: 'WEBSITE_VISITS',
      data: {
        url: href,
      },
    });
  };

  const closeDrawerPage = useCallback(
    (closeDrawer = false, registrationFormCallback = false) => {
      setCurrentPage(null);
      setNewUser(false);
      setIsDrawerPageOpen(false);
      if (registrationFormCallback) {
        setIsFormNavigation(false);
        history.replace(`/c/${id}`);
        return;
      }
      if (isFormNavigation && closeDrawer) {
        setIsFormNavigation(false);
        setTimeout(() => {
          history.push(`/c/${id}`);
        }, 0);
      }
    },
    [isFormNavigation, history, id]
  );

  useEffect(() => {
    if (navToForm) {
      setIsFormNavigation(navToForm);
    }
  }, [navToForm]);

  const changeDrawerPage = useCallback(
    (index, screenToUse?: ScreenType) => {
      setCurrentPage(index);
      if (details) {
        const newModule = details.modules[index];
        setMagicPayload({ moduleId: newModule?.id });

        setIsDrawerPageOpen(true);
        setPageTitle(details.modules[index]?.title);

        if (!screenToUse) {
          const screenToRender = initialScreenToShow(
            newModule,
            token,
            details?.registration,
            details?.product,
            registrationScreen,
            magicAction,
            registerProduct
          );

          if (screenToRender) {
            setScreen(screenToRender);
          }
        } else {
          setScreen(screenToUse);
        }
      }
    },
    [
      details,
      setMagicPayload,
      setMagicAction,
      currentPage,
      token,
      registrationScreen,
      magicAction,
      registerProduct,
    ]
  );

  useEffect(() => {
    if (currentPage !== null) {
      return;
    }

    if (
      details &&
      desktopView &&
      details?.modules?.length > 0 &&
      !openAutoDeployModal
    ) {
      let initialModuleIndex = 0;
      for (let i = 0; i < details.modules.length; i++) {
        const moduleType = details.modules[i]?.type;
        if (moduleType !== 'LINK_MODULE' && moduleType !== 'DOCUMENT_MODULE') {
          initialModuleIndex = i;
          break;
        }
      }
      history.push(
        `/c/${id}/${getModulePathname(
          details?.modules[initialModuleIndex].type,
          desktopView
        )}`
      );
      changeDrawerPage(initialModuleIndex);
    }
  }, [details, currentPage]);

  useEffect(() => {
    if (id && details?.product?.id !== previousProductId?.current) {
      logEvent({
        eventType: 'ENGAGEMENTS',
        event: 'USER_SCAN_A_TAG',
        data: id,
      });

      previousProductId.current = details?.product?.id;
    }
  }, [id, details?.product?.id]);

  useEffect(() => {
    if (id) setSlug(id);
  }, [id, setSlug]);

  useEffect(() => {
    if (!!details && !isDrawerPageOpen) {
      setPageTitle(details?.product?.displayName || details?.product?.name);
    }
  }, [isDrawerPageOpen, details, currentPage]);

  useEffect(() => {
    const event = previewEvent;

    if (
      previousEvent?.type === event?.type &&
      previousEvent?.data === event?.data
    ) {
      return;
    }

    if (event && event.type === 'changeDrawerPage') {
      if (currentPage === event.data) {
        return;
      }
      const buttons = buttonsArray();
      if (buttons && buttons?.length > event.data) {
        buttons[event.data].onClick();
      }
    } else if (event && event.type === 'closeDrawerPage') {
      removeFormLocalStorageValues();
      setIsFormNavigation(false);
      setMainDrawerOpen(false);
      closeDrawerPage();
    } else if (event && event.type === 'openMenu') {
      setMainDrawerOpen(true);
    } else if (event && event.type === 'closeMenu') {
      removeFormLocalStorageValues();
      setMainDrawerOpen(false);
      setIsFormNavigation(false);
      closeDrawerPage();
    }
  }, [previewEvent, closeDrawerPage, details?.modules]);

  // automatically open module upon redirect
  useEffect(() => {
    try {
      window.parent.postMessage({ type: 'appLoaded' }, '*');
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (registerCallMade || !token || !registeringProduct || !personalDetails)
      return;

    const register = async () => {
      try {
        if (!details) return;

        const warrantyModule = getWarrantyModule(details);

        openDrawer();
        setRegisterCallMade(true);

        // making this verbose as register doesn't need a warranty module id
        if (warrantyModule) {
          await registerProductAndFetch(
            warrantyModule?.id as string,
            emailConsent
          );
        } else {
          await registerProductAndFetch(undefined, emailConsent);
        }

        const registrationData = details?.registration;
        setMeta({
          title:
            registrationData?.confirmationHeader ||
            registrationTranslation(
              getSuccessTitle(registrationData?.registrationType)
            ),
          description: registrationData?.confirmationText,
        });

        if (warrantyModule) {
          const productDetailsWithWarranty = addWarrantyInformation(
            warrantyModule,
            details
          );
          setProductDetails(productDetailsWithWarranty);
        }

        showSuccess();

        logGtmEvent(PossibleGtmEventNames.REGISTRATION_COMPLETION);
      } catch (error: any) {
        setScreen(ScreenType.NONE);
        closeDrawerPage();
        closeSuccessDrawer();
        showToast({ message: 'Some error occured', type: 'error' });
      } finally {
        setRegisteringProduct(false);
        setRegisterCallMade(false);
        setReceiptData(null);
      }
    };

    if (details?.modules?.length) {
      register();
    }
  }, [
    productModule,
    details,
    registeringProduct,
    registerCallMade,
    changeDrawerPage,
    personalDetails,
    id,
    invalidateCache,
    token,
    emailConsent,
  ]);

  useEffect(() => {
    if (details?.product.customTheme) {
      if (details.product.customThemeConfig?.customAccentColor) {
        setBrandTheme(details.product.customThemeConfig.customAccentColor);
        getLocalStorage().setItem(
          'accentColor',
          details.product.customThemeConfig?.customAccentColor
        );
      } else {
        setBrandTheme(theme.primary);
        getLocalStorage().setItem('accentColor', '');
      }
      if (details?.product.customThemeConfig?.secondaryColor) {
        setSecondaryBrandColor(
          details.product.customThemeConfig.secondaryColor
        );
        getLocalStorage().setItem(
          'secondaryColor',
          details.product.customThemeConfig.secondaryColor
        );
      } else {
        setSecondaryBrandColor(theme.secondary);
        getLocalStorage().setItem('secondaryColor', '');
      }
      if (details.product.customThemeConfig?.buttonStyle) {
        setButtonStyle(
          details.product.customThemeConfig.buttonStyle as CornerStyle
        );
      } else setButtonStyle(CornerStyle.FULL_ROUND);
      if (details.product.customThemeConfig?.menuStyle) {
        setMenuStyle(
          details.product.customThemeConfig.menuStyle as CornerStyle
        );
      } else setMenuStyle(CornerStyle.FULL_ROUND);
      if (details.product.customThemeConfig?.inputStyle) {
        setInputStyle(
          details.product.customThemeConfig.inputStyle as CornerStyle
        );
      } else setInputStyle(CornerStyle.FULL_ROUND);
    } else {
      if (details?.brand?.customAccentColor) {
        setBrandTheme(details.brand.customAccentColor);
        getLocalStorage().setItem(
          'accentColor',
          details.brand.customAccentColor
        );
      } else {
        setBrandTheme(theme.primary);
        getLocalStorage().setItem('accentColor', '');
      }
      if (details?.brand.secondaryColor) {
        setSecondaryBrandColor(details?.brand.secondaryColor);
        getLocalStorage().setItem(
          'secondaryColor',
          details?.brand.secondaryColor
        );
      } else {
        setSecondaryBrandColor(theme.secondary);
        getLocalStorage().setItem('secondaryColor', '');
      }
      if (details?.brand?.buttonStyle) {
        setButtonStyle(details?.brand?.buttonStyle as CornerStyle);
      } else setButtonStyle(CornerStyle.FULL_ROUND);
      if (details?.brand?.menuStyle) {
        setMenuStyle(details?.brand?.menuStyle as CornerStyle);
      } else setMenuStyle(CornerStyle.FULL_ROUND);
      if (details?.brand?.inputStyle) {
        setInputStyle(details?.brand?.inputStyle as CornerStyle);
      } else setInputStyle(CornerStyle.FULL_ROUND);
    }
  }, [
    details,
    setBrandTheme,
    setSecondaryBrandColor,
    setButtonStyle,
    setInputStyle,
    setMenuStyle,
  ]);

  useEffect(() => {
    if (details) {
      for (let x = 0; x < details?.modules?.length; x++) {
        if (details?.modules[x]?.registrationRequired)
          setSignInRedirect(`/c/${id}`);
      }
    }
  }, [details, setSignInRedirect, id]);

  const calculateWarrantyDrawerTitle = () => {
    let title: string = '';
    if (details && currentPage !== null) {
      if (!details.modules[currentPage]) return;
      const moduleInfo = details.modules[currentPage]
        .moduleInfo as WarrantyModuleType;
      const { enablePostRegCTA, postRegCTA } = details.modules[currentPage];

      if (moduleInfo?.activated || moduleInfo?.approvalStatus)
        title = enablePostRegCTA && postRegCTA ? postRegCTA : t('viewWarranty');
      else title = details.modules[currentPage].title;
    }
    return title;
  };

  const warrantyDrawerTitle = calculateWarrantyDrawerTitle();

  const FallbackLoader = () => (
    <div className='w-full h-full flex items-center justify-center'>
      <LoadingIndicator />
    </div>
  );

  let buttonsArray = useCallback(() => {
    let buttons: ModuleButtonType[] = [];
    if (details) {
      const modules = details?.modules;

      for (let x = 0; x < modules?.length; x++) {
        let title: string = modules[x].title;
        let autoDeploy: boolean = modules[x].autoDeploy;
        let moduleType: PossibleModulesType = modules[x].type;
        let moduleData: VideoModuleType | LinkModuleType | null = null;
        switch (modules[x].type) {
          case 'WARRANTY_MODULE':
            const moduleInfo = modules[x].moduleInfo as WarrantyModuleType;

            const { enablePostRegCTA, postRegCTA } = modules[x];

            if (moduleInfo?.activated) {
              title =
                enablePostRegCTA && postRegCTA ? postRegCTA : t('viewWarranty');
            } else if (
              moduleInfo?.approvalStatus &&
              moduleInfo?.approvalStatus !== ApprovalStatus.INCOMPLETE
            ) {
              // this suggests that the user has already activated the warranty
              // but the warranty is not yet active
              title =
                enablePostRegCTA && postRegCTA ? postRegCTA : t('viewWarranty');
            } else {
              title = modules[x].title;
            }
            break;
          case 'VIDEO_MODULE':
            moduleData = modules[x].moduleInfo as VideoModuleType;
            break;
          case 'SWEEPSTAKES_MODULE':
            const sweepstakeModule = modules[x]
              .moduleInfo as SweepstakesModuleType;
            title =
              sweepstakeModule.customizePostRegistrationCta &&
              sweepstakeModule.registrationId &&
              sweepstakeModule.postRegistrationCta
                ? sweepstakeModule.postRegistrationCta
                : modules[x].title;
            break;
          default:
            title = modules[x].title;
            break;
        }
        let buttonObject: ModuleButtonType = {
          title,
          onClick: () => {
            const module = modules[x];
            setProductModule(module?.id);
            getLocalStorage().setItem('brij-current-module-id', module?.id);

            if (modules[x].registrationRequired && !user) {
              history.push(
                `/c/${id}/${getModulePathname(moduleType, desktopView)}`
              );
            } else if (
              modules[x]?.type !== 'LINK_MODULE' &&
              modules[x]?.type !== 'DOCUMENT_MODULE'
            ) {
              history.push(
                `/c/${id}/${getModulePathname(moduleType, desktopView)}`
              );
            }

            changeDrawerPage(x);
            setMainDrawerOpen(true);

            logEvent({
              eventType: 'ENGAGEMENTS',
              event: 'MODULE_CLICKED',
              moduleType: module.type,
              moduleId: module.id,
            });
            // always remove start screen when clicked to start a new one
            getLocalStorage().removeItem(
              `brij-form-${slug}-${formRegistration?.id}-start-screen-shown`
            );
            let isFormRegCompelte = getLocalStorage().getItem(
              `brij-form-${slug}-${formRegistration?.id}-complete`
            );
            if (
              module.registrationRequired &&
              !isFormRegCompelte &&
              formRegistration
            ) {
              getLocalStorage().setItem(
                `brij-form-${slug}-${formRegistration?.id}-return-module-id`,
                '' + x
              );
            } else {
              getLocalStorage().removeItem(
                `brij-form-${slug}-${formRegistration?.id}-return-module-id`
              );
            }
          },
          isHighlight: x === 0,
          autoDeploy: autoDeploy,
          moduleType: moduleType,
          moduleData: moduleData,
          registrationRequired: modules[x].registrationRequired && !user,
        };
        buttons.push(buttonObject);
      }
    }
    return buttons;
  }, [
    t,
    details,
    changeDrawerPage,
    logEvent,
    brandTheme,
    setProductModule,
    formRegistration,
  ]);

  const leadModule: any = useMemo(() => {
    return details?.leadModule || {};
  }, [details]);

  const leadInformation = useMemo(() => {
    // First, check if it a unit tag. In that case, showing info
    // about it takes precedence over others

    if (details?.unitTagDetails) {
      return (
        <div className='flex flex-col items-end'>
          <span className='text-xs leading-5'>
            {details.unitTagDetails.registered ? 'Registered to' : 'Status'}
          </span>
          <span className='text-xs text-right font-semibold leading-5'>
            {details.unitTagDetails.registered
              ? user?.email === details?.unitTagDetails?.email
                ? 'You'
                : details.unitTagDetails.user
              : 'Unregistered'}
          </span>
        </div>
      );
    }

    switch (leadModule.type) {
      case 'SHOPPING_MODULE':
        if (leadModule.moduleInfo.showProductPriceHome) {
          const defaultVariantDetails =
            leadModule?.moduleInfo?.defaultVariantDetails;
          const price = defaultVariantDetails?.contextualPricing?.amount || 0;
          const discountedPrice =
            defaultVariantDetails?.discountedContextualPricing;
          return !discountedPrice ? (
            <span className='text-base font-semibold'>{`$${price}`}</span>
          ) : (
            <div className='flex flex-col items-end'>
              <span className='text-[0.8rem] line-through text-muted'>
                {`${parseFloat(price).toLocaleString(navigator.language, {
                  style: 'currency',
                  currency: defaultVariantDetails?.contextualPricing
                    ?.currencyCode
                    ? defaultVariantDetails?.contextualPricing?.currencyCode
                    : 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </span>
              <span className='text-base font-semibold'>
                {`${parseFloat(discountedPrice).toLocaleString(
                  navigator.language,
                  {
                    style: 'currency',
                    currency: defaultVariantDetails?.contextualPricing
                      ?.currencyCode
                      ? defaultVariantDetails?.contextualPricing?.currencyCode
                      : 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}`}
              </span>
            </div>
          );
        } else return null;

      case 'WARRANTY_MODULE':
        const { registeredTo, period, duration } = leadModule?.moduleInfo || {};
        const { enableWarrantyOnHomePage } = leadModule;
        const tagType = details?.product?.tagType;

        if (registeredTo && tagType === 'Unit') {
          return (
            <div className='flex flex-col items-end'>
              <span className='leading-5 text-xs'>
                {t('leadInformation.registeredTo')}
              </span>
              <span className='font-semibold text-xs text-right'>
                {registeredTo}
              </span>
            </div>
          );
        }

        if (!period && !duration) return null;
        if (!enableWarrantyOnHomePage) return null;

        return (
          <div className='flex flex-col items-end'>
            <span className='leading-5 text-xs'>
              {t('leadInformation.warranty')}
            </span>
            <span className='font-semibold text-xs text-right'>
              {`${duration?.label !== 'Lifetime' ? period : ''} ${
                parseInt(period) === 1
                  ? duration?.label.substring(0, duration?.label?.length - 1)
                  : duration?.label
              }`}
            </span>
          </div>
        );
      default:
        return null;
    }
  }, [leadModule, details, t]);

  useEffect(() => {
    const brijFormRegistration = getLocalStorage().getItem(
      `brij-form-${slug}-${formRegistration?.id}-return-module-id`
    );
    const brijFormReturnModule = parseInt(brijFormRegistration as string, 10);
    if (brijFormRegistration) setCurrentPage(brijFormReturnModule);
  }, []);

  useEffect(() => {
    // making sure product is loaded
    if (!details?.product?.name) {
      return;
    }

    if (magicAction === MAGIC_ACTION.OPEN_MODULE) {
      setUserClosedAutoDeployDrawer(true);

      const moduleIndex = details?.modules?.findIndex(
        (moduleDetail) =>
          moduleDetail.id === (productModule || warrantyModule?.id)
      );

      if (moduleIndex !== -1) {
        changeDrawerPage(moduleIndex);
        setMainDrawerOpen(true);
      } else if (details?.modules[0]) {
        changeDrawerPage(0);
        setMainDrawerOpen(true);
      }
    }
  }, [magicAction, details?.product?.name]);

  useEffect(() => {
    // making sure product is loaded
    if (!details?.product?.name) {
      return;
    }

    // Maybe remove this from here in the future
    // but this is critical to have because
    // if it's done in the above useEffect
    // autodeploy also opens when magicLink is used
    // so we have to wait until we reach in here
    if (magicAction === MAGIC_ACTION.OPEN_MODULE) {
      setMagicAction(MAGIC_ACTION.NONE);
      return;
    }

    if (!details?.product?.autoDeploy) {
      return;
    }

    if (userClosedAutoDeployDrawer) {
      return;
    }

    if (details?.product.registeredToCurrentUser) {
      return;
    }

    if (loading && !authFetched) {
      return;
    }

    setMainDrawerOpen(false);

    setOpenAutoDeployModal(true);
    const warrantyModule = getWarrantyModule(details);
    setProductModule(warrantyModule?.id || 'dummyProductModule');
  }, [
    details?.product?.autoDeploy,
    details?.product.registeredToCurrentUser,
    userClosedAutoDeployDrawer,
  ]);

  const renderDrawerPage = useCallback(() => {
    if (details) {
      const module = details?.modules[currentPage as number];
      let moduleType: string | undefined = module?.type;
      let mulberry: WarrantyModuleType['mulberry'] | null = null;
      if (module?.moduleInfo) {
        const warrantyModuleInfo = module?.moduleInfo as WarrantyModuleType;
        mulberry = warrantyModuleInfo.mulberry;
      }
      if (!mulberry) {
        let result = details?.modules?.findIndex(
          (element) => element.type === 'WARRANTY_MODULE'
        );
        const module = details?.modules[result as number];
        if (module?.moduleInfo) {
          const warrantyModuleInfo = module?.moduleInfo as WarrantyModuleType;
          mulberry = warrantyModuleInfo.mulberry;
        }
      }
      const renderOtherModules = () => {
        switch (moduleType) {
          case 'DOCUMENT_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <DocumentDrawer
                  drawerTitle={details?.modules[currentPage as number]?.title}
                  drawerData={module?.moduleInfo as DocumentModuleType}
                  registrationRequired={
                    details?.modules[currentPage as number]
                      ?.registrationRequired && !user
                  }
                  closeDrawer={closeDrawerPage}
                />
              </Suspense>
            );
          case 'CUSTOM_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <CustomDrawer
                  drawerTitle={details?.modules[currentPage as number]?.title}
                  drawerData={module?.moduleInfo as CustomModuleType}
                />
              </Suspense>
            );
          case 'WARRANTY_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <WarrantyDrawer
                  drawerTitle={warrantyDrawerTitle || ''}
                  warrantyData={module?.moduleInfo as WarrantyModuleType}
                  warrantyId={module?.id}
                  warrantyModule={module}
                  registrationData={details?.registration}
                />
              </Suspense>
            );
          case 'AB899_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <AB899Drawer
                  drawerTitle={details?.modules[currentPage as number]?.title}
                  drawerData={module?.moduleInfo as AB899ModuleType}
                  brandId={details?.brand?.id}
                />
              </Suspense>
            );
          case 'SWEEPSTAKES_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <SweepstakesDrawer
                  drawerTitle={details?.modules[currentPage as number]?.title}
                  drawerData={module?.moduleInfo as SweepstakesModuleType}
                />
              </Suspense>
            );
          case 'REFERRAL_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <ReferralDrawer
                  drawerTitle={details?.modules[currentPage as number]?.title}
                  referralData={module?.moduleInfo as ReferralModuleType}
                />
              </Suspense>
            );
          case 'DISCOUNT_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <DiscountDrawer
                  discountData={module?.moduleInfo as DiscountModuleType}
                  drawerTitle={details?.modules[currentPage as number]?.title}
                />
              </Suspense>
            );
          case 'SHOPPING_MODULE':
            const data = details?.modules[currentPage as number]
              ?.moduleInfo as ShoppingModuleType;
            return (
              <Suspense fallback={<FallbackLoader />}>
                <ShopDrawer
                  data={data}
                  brandId={details.brand.id}
                  brandName={details.brand.name}
                />
              </Suspense>
            );
          case 'VIDEO_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <VideoDrawer
                  closeDrawer={closeDrawerPage}
                  drawerTitle={details?.modules[currentPage as number]?.title}
                  drawerData={module?.moduleInfo as VideoModuleType}
                />
              </Suspense>
            );
          case 'LINK_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <LinkDrawer
                  closeDrawer={closeDrawerPage}
                  drawerData={module?.moduleInfo as LinkModuleType}
                  drawerTitle={details?.modules[currentPage as number]?.title}
                  registrationRequired={
                    details?.modules[currentPage as number]
                      ?.registrationRequired && !user
                  }
                />
              </Suspense>
            );
          case 'IFRAME_MODULE':
            return (
              <Suspense fallback={<FallbackLoader />}>
                <IframeDrawer
                  drawerData={module?.moduleInfo as any}
                  drawerTitle={details?.modules[currentPage as number]?.title}
                />
              </Suspense>
            );
          case 'REVIEWS_MODULE':
            const dataReview = details?.modules[currentPage as number]
              ?.moduleInfo as FormDetailModel[];

            return (
              <FormProvider key={details?.modules[currentPage as number]?.id}>
                <div className='flex h-full'>
                  <Suspense fallback={<FallbackLoader />}>
                    {dataReview?.length || isPreviewMode() ? (
                      <FormDrawer
                        formModuleData={{
                          ...details.modules[currentPage as number],
                          startScreenContent: module?.startScreenContent || ' ',
                        }}
                        data={dataReview || []}
                        closeDrawer={(...args) => {
                          closeDrawerPage(...args);
                        }}
                        changeDrawerPage={changeDrawerPage}
                        type='REVIEW'
                      />
                    ) : null}
                  </Suspense>
                </div>
              </FormProvider>
            );

          case 'FORMS_MODULE':
            const dataForm = details?.modules[currentPage as number]
              ?.moduleInfo as FormDetailModel[];

            return (
              <FormProvider key={details?.modules[currentPage as number]?.id}>
                <div className='flex h-full'>
                  <Suspense fallback={<FallbackLoader />}>
                    {dataForm?.length || isPreviewMode() ? (
                      <FormDrawer
                        formModuleData={{
                          ...details.modules[currentPage as number],
                          startScreenContent: module?.startScreenContent || ' ',
                        }}
                        data={dataForm || []}
                        closeDrawer={(...args) => {
                          closeDrawerPage(...args);
                        }}
                        changeDrawerPage={changeDrawerPage}
                      />
                    ) : null}
                  </Suspense>
                </div>
              </FormProvider>
            );
          default:
            return null;
        }
      };

      return (
        <RegistrationDrawer
          mulberry={mulberry}
          isNewUser={isNewUser}
          product={details?.product}
          closePage={closeDrawerPage}
          warrantyData={warrantyModule}
          brandName={details?.brand?.name}
          onUserUpdate={() => setNewUser(false)}
          registrationData={details?.registration}
          warrantyId={warrantyModule?.id as string}
          drawerTitle={details?.modules[currentPage as number]?.title}
          html={mulberry ? null : details?.registration?.registrationText}
          renderNull={
            desktopView && currentPage === null && openAutoDeployModal
          }
        >
          {renderOtherModules()}
        </RegistrationDrawer>
      );
    }
  }, [
    t,
    details,
    isNewUser,
    brandTheme,
    currentPage,
    closeDrawerPage,
    warrantyDrawerTitle,
    previewAuthenticated,
  ]);

  const logo = useCallback(
    (image: string) =>
      image ? (
        <img
          src={image}
          alt='brand-logo'
          className='relative w-full max-w-[156px] h-[52px] object-contain'
        />
      ) : null,
    []
  );

  const pageLoading = loading || !authFetched;

  const currentModule = details?.modules[currentPage as number];

  const fullWidthIframe: boolean = useMemo(() => {
    if (desktopView) return false;
    if (
      details?.modules[currentPage as number] &&
      currentModule?.type === 'IFRAME_MODULE'
    ) {
      const moduleInfo = currentModule.moduleInfo as IframeModuleType | null;
      return moduleInfo?.addMargin ? false : true;
    }
    return false;
  }, [details, currentPage, currentModule]);

  const bottomDrawer = (
    <>
      <BottomDrawer
        title={pageTitle}
        position={position}
        buttons={buttonsArray()}
        setPosition={setPosition}
        product={details?.product}
        closeChild={closeDrawerPage}
        currentPage={currentPage}
        logo={logo(getLogoURL(details))}
        isChildOpen={desktopView ? true : isDrawerPageOpen}
        mainDrawerOpen={desktopView ? true : mainDrawerOpen}
        setUserClosedAutoDeployDrawer={setUserClosedAutoDeployDrawer}
        setOpenAutoDeployModal={setOpenAutoDeployModal}
        openAutoDeployModal={openAutoDeployModal}
        fullWidth={fullWidthIframe}
        socials={details?.brand?.social}
        leadInformation={leadInformation}
        subtitle={details?.product?.subtitle}
        setMainDrawerOpen={setMainDrawerOpen}
        allowSocialIcons={details?.product.allowSocialIcons}
        allowClose={allowClose}
        registerProductAgain={() => {
          changeDrawerPage(0);
          setMainDrawerOpen(true);
          setScreen(ScreenType.MULTIPLE_REGISTRATION);
        }}
      >
        {renderDrawerPage()}
      </BottomDrawer>
      <AutoDeploy
        isOpen={openAutoDeployModal}
        isProductRegistered={!!details?.product.registeredToCurrentUser}
        closeDrawer={() => {
          if (details && desktopView) {
            let initialModuleIndex = 0;
            for (let i = 0; i < details.modules.length; i++) {
              const moduleType = details.modules[i]?.type;
              if (
                moduleType !== 'LINK_MODULE' &&
                moduleType !== 'DOCUMENT_MODULE'
              ) {
                initialModuleIndex = i;
                break;
              }
            }
            history.push(
              `/c/${id}/${getModulePathname(
                details?.modules[initialModuleIndex].type,
                desktopView
              )}`
            );
            changeDrawerPage(initialModuleIndex);
          } else history.push(`/c/${id}`);
          setOpenAutoDeployModal(false);
        }}
      >
        <RegistrationDrawer
          isNewUser={isNewUser}
          product={details?.product}
          closePage={closeDrawerPage}
          warrantyData={warrantyModule}
          brandName={details?.brand?.name}
          drawerTitle={currentModule?.title}
          onUserUpdate={() => setNewUser(false)}
          registrationData={details?.registration}
          warrantyId={warrantyModule?.id as string}
          html={details?.registration?.registrationText}
        >
          <CloseAutoDeployDrawer
            setMainDrawerOpen={setMainDrawerOpen}
            onClose={() => {
              if (details && desktopView) {
                let initialModuleIndex = 0;
                for (let i = 0; i < details.modules.length; i++) {
                  const moduleType = details.modules[i]?.type;
                  if (
                    moduleType !== 'LINK_MODULE' &&
                    moduleType !== 'DOCUMENT_MODULE'
                  ) {
                    initialModuleIndex = i;
                    break;
                  }
                }
                history.push(
                  `/c/${id}/${getModulePathname(
                    details?.modules[initialModuleIndex].type,
                    desktopView
                  )}`
                );
                changeDrawerPage(initialModuleIndex, ScreenType.CURRENT_MODULE);
              } else history.push(`/c/${id}`);
              setOpenAutoDeployModal(false);
            }}
          />
        </RegistrationDrawer>
      </AutoDeploy>
    </>
  );

  const digitalHub = (
    <DigitalHub
      modules={details?.digitalModules}
      dimBackground={details?.product.dimBackground || false}
      logo={logo(getLogoURL(details)) ? logo(getLogoURL(details)) : null}
      socials={details?.product.allowSocialIcons ? details?.brand.social : null}
      buttonStyle={
        details?.product.customTheme
          ? details?.product.customThemeConfig?.buttonStyle
          : details?.brand.buttonStyle
      }
    />
  );

  const getExperience = (qrCodeDestination: string | undefined) => {
    switch (qrCodeDestination) {
      case PossibleExperienceType.PRODUCT_EXPERIENCE:
        return bottomDrawer;
      case PossibleExperienceType.DIGITAL_HUB:
        return digitalHub;
      case PossibleExperienceType.SMS_OPT_IN:
        return details ? (
          <SmsOptIn
            logo={logo(getLogoURL(details)) ? true : false}
            data={details.product.smsOptIn ? details.product?.smsOptIn : null}
            socials={details?.brand?.social || null}
            brandName={details?.brand?.name}
            brandId={details?.brand?.id}
          />
        ) : null;
      default:
        return bottomDrawer;
    }
  };

  return pageLoading ? (
    <div className='absolute top-0 left-0 w-full h-full flex'>
      <LoadingIndicator />
    </div>
  ) : (
    <PageWrapper
      transparent
      logo={logo(getLogoURL(details))}
      hideHeader={
        (details?.product.qrCodeDestination ===
          PossibleExperienceType.SMS_OPT_IN ||
          details?.product.qrCodeDestination ===
            PossibleExperienceType.DIGITAL_HUB) &&
        !logo(getLogoURL(details))
      }
      hideMenu={
        details?.product.qrCodeDestination ===
          PossibleExperienceType.SMS_OPT_IN || details?.brand?.hideMenu
      }
    >
      <AgeGate ageLimit={details?.product.ageLimit || 21} />
      <ContentGate
        data={details?.product?.contentGateModule as ContentGateType}
      />
      <Helmet>
        {details?.brand?.name && (
          <title>{`${details.product.name} from ${details.brand.name} Powered by Brij`}</title>
        )}
      </Helmet>
      {((desktopView &&
        details?.product.qrCodeDestination ===
          PossibleExperienceType.DIGITAL_HUB) ||
        !desktopView) && <MediaBackground />}
      <div style={pageLoading && !mainDrawerOpen ? { display: 'none' } : {}}>
        {details && (
          <CatchLinkWrapper onLinkClick={onLinkClick}>
            {getExperience(details.product.qrCodeDestination)}
          </CatchLinkWrapper>
        )}
      </div>
    </PageWrapper>
  );
};

export default ProductDetails;
