import { getLocalStorage } from './storage';

export const removeFormLocalStorageValues = () => {
  const localStorage = getLocalStorage();
  const allKeys = localStorage.getAllKeys();

  allKeys
    .filter(
      (x: string) =>
        x.startsWith('brij-form-') && !x.endsWith('-return-module-id')
    )
    .filter(
      (x: string) => x.startsWith('brij-form-') && !x.endsWith('-complete')
    )
    .forEach((x: string) => localStorage.removeItem(x));

  localStorage.removeItem('brij-current-module-id');
};

export const removeRegistrationFormLocalStorageValues = (
  slug: string | null,
  formModuleId: string
) => {
  const localStorage = getLocalStorage();
  localStorage.removeItem(`brij-form-${slug}-${formModuleId}-complete`);
};
