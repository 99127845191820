export enum QuestionType {
  TEXT = 'text',
  DROP_DOWN = 'drop-down',
  MULTIPLE_CHOICE = 'multiple-choice',
  CHECKBOX = 'checkbox',
  FILE = 'file-upload',
  DATE_SELECTOR = 'date-selector',
  PHONE_NUMBER = 'phone-number',
  ADDRESS = 'address',
  EMAIL = 'email',
  NUMBER = 'number',
  RETAIL_CHANNEL = 'retail-channel',
  REVIEW_RATING = 'review-rating',
  NAME = 'name',
  CONTENT_BLOCK = 'content-block',
}
