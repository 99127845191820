import { PossibleModulesType } from 'types/ProductDetailsType';

export const getModulePathname = (
  moduleType: PossibleModulesType,
  desktopView: boolean
) => {
  switch (moduleType) {
    case 'WARRANTY_MODULE':
      return 'warranty';
    case 'CUSTOM_MODULE':
      return 'custom';
    case 'SHOPPING_MODULE':
      return 'shop';
    case 'REGISTRATION_MODULE':
      return 'registration';
    case 'REFERRAL_MODULE':
      return 'referral';
    case 'FORMS_MODULE':
      return 'form';
    case 'DISCOUNT_MODULE':
      return 'discount';
    case 'IFRAME_MODULE':
      return 'iframe';
    case 'VIDEO_MODULE':
      return desktopView ? 'video' : '';
    default:
      return '';
  }
};
