import React, { useState } from 'react';
import { User } from 'firebase/auth';
import { theme } from 'styles/theme';
import { Link } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import { isPreviewMode } from 'utils/preview';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'context/global/GlobalContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as Arrow } from 'assets/icons/svg/chevron-up.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import {
  ModuleInfoType,
  SweepstakesModuleType,
  WarrantyModuleType,
} from 'types/ProductDetailsType';
import RegistrationLogin from 'components/registration/RegistrationLogin';
import UnitTagLoginForm from 'components/forms/UnitTagLoginForm';
import CoverageTable from 'components/tables/CoverageTable';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import useRedirectLink from 'hooks/useRedirectLink';
import useElementSize from 'hooks/useElementSize';

interface AuthDrawerProps {
  onAuthComplete: (provider: string, user?: User) => Promise<void>;
  mulberry?: WarrantyModuleType['mulberry'] | null;
  html: string | undefined | null;
  customLegalText?: string;
  productName?: string;
  brandName?: string;
  animated?: boolean;
  margin?: string;
}

const AuthDrawer: React.FC<AuthDrawerProps> = ({
  html,
  mulberry,
  animated,
  brandName,
  productName,
  onAuthComplete,
  customLegalText,
}) => {
  const [showCoverageTable, toggleCoverageTable] = useState<boolean>(false);
  const redirectToExternalLink = useRedirectLink();
  const [tableRef, { height }] = useElementSize();
  const { slug, productDetails } = useGlobal();
  const { brandTheme } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.authDrawer',
  });

  const isUnitTagFlow =
    productDetails?.unitTagDetails && productDetails.unitTagDetails.registered;

  const getSweepstakesGameRules = () => {
    if (productDetails) {
      let sweepstakesModule: ModuleInfoType | undefined =
        productDetails?.modules?.find(
          (module) => module.type === 'SWEEPSTAKES_MODULE'
        );
      if (sweepstakesModule) {
        let sweepstakesModuleInfo: SweepstakesModuleType =
          sweepstakesModule.moduleInfo as SweepstakesModuleType;
        return sweepstakesModuleInfo?.gameRules ? true : false;
      } else return false;
    } else return false;
  };

  return (
    <Animated
      isVisible
      animateOnMount
      className='w-full h-full'
      animationIn='slideInRight'
      animationOut='slideOutLeft'
      animationInDuration={200}
      animationOutDuration={200}
    >
      <div
        className={`relative w-full h-full flex flex-col items-center justify-start overflow-x-hidden ${
          animated
            ? 'pt-6 pb-8 border border-solid border-t-0 border-l-0 border-r-0 border-b-2 border-authDrawerBorder'
            : 'pb-8'
        }`}
      >
        {html && !isUnitTagFlow && <HtmlWrapper html={html} />}
        {isUnitTagFlow && (
          <div className='w-full mt-4'>
            <HtmlWrapper
              html={`
                <h3>${t('unitTag.heading')}</h3>
                <p>${t('unitTag.description')}</p>
              `}
            />
          </div>
        )}
        {mulberry && (
          <div className='w-full flex flex-col items-center justify-start gap-4 duration-300'>
            <div
              className='w-full flex items-center justify-center mt-4 gap-2 cursor-pointer'
              onClick={() => toggleCoverageTable(!showCoverageTable)}
            >
              <span className='text-base font-semibold text-dark underline cursor-pointer'>
                {t('viewDetails')}
              </span>
              <Arrow
                className={`${
                  showCoverageTable ? 'rotate-0' : 'rotate-180'
                } duration-500`}
              />
            </div>
            <div className='flex overflow-hidden'>
              <div className='flex m-auto'>
                <div
                  ref={tableRef}
                  className={`h-full flex flex-col pb-4 duration-300 ${
                    showCoverageTable ? 'translate-y-0' : '-translate-y-full'
                  }`}
                >
                  <CoverageTable
                    headers={["What's Covered", 'mulberry', 'Manu. Warranty']}
                    tableData={mulberry?.coverages}
                  />
                  <div
                    className='flex items-start justify-start mt-3 ml-1.5 cursor-pointer'
                    onClick={() =>
                      redirectToExternalLink(mulberry?.policyTermsUrl, '_blank')
                    }
                  >
                    <ExternalLink
                      className='-mt-0.5 mr-1'
                      fill={brandTheme || theme.primary}
                    />
                    <p className='text-xs font-medium text-primary'>
                      {t('fullTermsLink')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className='relative w-full h-full flex flex-col duration-300'
          style={{
            transform: !showCoverageTable
              ? `translateY(-${height}px)`
              : 'translateY(0)',
          }}
        >
          {isUnitTagFlow ? (
            <UnitTagLoginForm isDrawer handleLogin={onAuthComplete} />
          ) : (
            <RegistrationLogin onRegistration={onAuthComplete} />
          )}
          <div className='flex py-2'>
            {customLegalText ? (
              <HtmlWrapper
                html={customLegalText}
                styles='mt-4 [&_p]:text-left [&_p]:text-xxs [&_p]:text-darkGray'
              />
            ) : (
              <p className='text-xxs text-left text-darkGray'>
                {!isUnitTagFlow ? (
                  <>
                    {t('termsAndConditions.part1')}
                    {brandName}
                    {t('termsAndConditions.part2')}
                    {mulberry
                      ? t('termsAndConditions.mulberryAndBrijBrand')
                      : t('termsAndConditions.brijBrand')}

                    <Link
                      to={
                        isPreviewMode()
                          ? `/c/${slug}`
                          : productDetails
                          ? `/p/${slug}`
                          : '/p/BRIJ'
                      }
                      className='underline cursor-pointer'
                    >
                      {t('termsAndConditions.linkText')}
                    </Link>
                    {getSweepstakesGameRules() && ` ${t('and')} `}
                    {getSweepstakesGameRules() && (
                      <Link
                        to={
                          isPreviewMode()
                            ? `/c/${slug}`
                            : productDetails
                            ? `/s/${slug}`
                            : '/s/BRIJ'
                        }
                        className='underline cursor-pointer'
                      >
                        {t('sweepstakesRules')}
                      </Link>
                    )}
                    {'.'}
                    {t('termsAndConditions.part3')}
                  </>
                ) : (
                  <>{t('termsAndConditions.unitTagDisclaimer')}</>
                )}
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='underline cursor-pointer'
                  href={`mailto:support@brij.it?subject=Help with ${brandName} ${productName} (${slug})`}
                >
                  {t('termsAndConditions.helpEmail')}
                </a>
                {'.'}
              </p>
            )}
          </div>
        </div>
      </div>
    </Animated>
  );
};

export default AuthDrawer;
